// extracted by mini-css-extract-plugin
export var button = "superCompressedLiteraryIllustration-module--button--p4aCa";
export var buttons = "superCompressedLiteraryIllustration-module--buttons--4sKpm";
export var close = "superCompressedLiteraryIllustration-module--close--2rNcv";
export var curThumNail = "superCompressedLiteraryIllustration-module--curThumNail--BqLqJ";
export var decription = "superCompressedLiteraryIllustration-module--decription--tVW4p";
export var decription2 = "superCompressedLiteraryIllustration-module--decription2--Dvkf8";
export var desc = "superCompressedLiteraryIllustration-module--desc--S11mB";
export var desc2 = "superCompressedLiteraryIllustration-module--desc2--zFIDV";
export var desc3 = "superCompressedLiteraryIllustration-module--desc3--EFGby";
export var eachTitle = "superCompressedLiteraryIllustration-module--eachTitle--iV3e7";
export var imgAppear = "superCompressedLiteraryIllustration-module--imgAppear--Z9bpD";
export var imgHide = "superCompressedLiteraryIllustration-module--imgHide--NDCHq";
export var isShowCSS = "superCompressedLiteraryIllustration-module--isShowCSS--qaRIh";
export var modalArea = "superCompressedLiteraryIllustration-module--modalArea--qGhG8";
export var modalBg = "superCompressedLiteraryIllustration-module--modalBg--1GzPP";
export var modalContent = "superCompressedLiteraryIllustration-module--modalContent--lO9bG";
export var modalContentCSS = "superCompressedLiteraryIllustration-module--modalContentCSS--duk1a";
export var modalWrapper = "superCompressedLiteraryIllustration-module--modalWrapper--hHuaq";
export var modalWrapperScale = "superCompressedLiteraryIllustration-module--modalWrapperScale--ofzOf";
export var modalWrapperWrapper = "superCompressedLiteraryIllustration-module--modalWrapperWrapper--5IIi1";
export var search = "superCompressedLiteraryIllustration-module--search--8u3AC";
export var select = "superCompressedLiteraryIllustration-module--select--RCfYb";
export var thumNail = "superCompressedLiteraryIllustration-module--thumNail--PsOfK";
export var thumNailUme = "superCompressedLiteraryIllustration-module--thumNailUme--LaXBQ";
export var thumNails = "superCompressedLiteraryIllustration-module--thumNails--XVW+A";
export var thumbImg = "superCompressedLiteraryIllustration-module--thumbImg--CAcO9";