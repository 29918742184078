import React, { useState, useEffect } from 'react';
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby"

import Seo from "../../components/seo"
import Layout from "../../components/layout"
import Article from "../../components/article"
import useWindowSize from "../../utilt/useWindowSize"

//CSS
import { eachTitle } from "./superCompressedLiteraryIllustration.module.css"
import { desc } from "./superCompressedLiteraryIllustration.module.css"
import { desc2 } from "./superCompressedLiteraryIllustration.module.css"
import { desc3 } from "./superCompressedLiteraryIllustration.module.css"
import { thumNails } from "./superCompressedLiteraryIllustration.module.css"
import { thumNail } from "./superCompressedLiteraryIllustration.module.css"
import { curThumNail } from "./superCompressedLiteraryIllustration.module.css"
import { thumbImg } from "./superCompressedLiteraryIllustration.module.css"
import { modalArea } from "./superCompressedLiteraryIllustration.module.css"
import { isShowCSS } from "./superCompressedLiteraryIllustration.module.css"
import { modalBg } from "./superCompressedLiteraryIllustration.module.css"
import { modalWrapperWrapper } from "./superCompressedLiteraryIllustration.module.css"
import { modalWrapperScale } from "./superCompressedLiteraryIllustration.module.css"
import { modalWrapper } from "./superCompressedLiteraryIllustration.module.css"
import { modalContentCSS } from "./superCompressedLiteraryIllustration.module.css"
import { decription } from "./superCompressedLiteraryIllustration.module.css"
import { decription2 } from "./superCompressedLiteraryIllustration.module.css"
import { close } from "./superCompressedLiteraryIllustration.module.css"
import { buttons } from "./superCompressedLiteraryIllustration.module.css"
import { search } from "./superCompressedLiteraryIllustration.module.css"
import { select } from "./superCompressedLiteraryIllustration.module.css"
import { button } from "./superCompressedLiteraryIllustration.module.css"


import getLanguage from "../../utilt/getLangueage"
import getIsTouchscreen from "../../utilt/getIsTouchscreen"

//軽量化用（何度もソートしないでいいように）
let sortedbyFilename = [];
let sortedBySurname = [];
let sortedBySurnameJP = [];
let sortedByTitle = [];
let sortedByTitleJp = [];
let sortedByYear = [];
let searchedImages = [];
let randomSortedImages = [];
let isRandomSorted, preSortNum, preIsDownSort, preLang, preSearchTxt = "";//描画枚にソートしないでいいように、のため（重要）
let timerID; //検索ワード変更のタイミングを遅らすのに使う

let allImages = [];
let OriginalImagesLength;



function randomSort(arr) {
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
}
function deepCopy(a) {
  return JSON.parse(JSON.stringify(a));
}

/**
 * ページ
 */
const SuperCompressedLiteraryIllustration = (props) => {
  const [lang, setLang] = useState(getLanguage());
  function setLanguage(enOrJa) {
    setLang(enOrJa);
  }
  const winSize = useWindowSize() || props.size || { width: 640, height: 948 }


  //画像データ取得
  const data = useStaticQuery(graphql`query MyQuerySuperCompressedLiteraryIllustration {
    allFile(filter: {extension: {regex: "/(png)/"}, relativeDirectory: {eq: "superCompressedLiteraryIllustration"}}) {
      edges {
        node {
          base
          childImageSharp {
            gatsbyImageData(quality: 50, placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
          }
        }
      }
    }
    allSuperCompressedLiteraryIllustrationJson {
      edges {
        node {
          filename
          author
          authorJp
          surname
          surnameJp
          title
          titleJp
          titleJpSort
          sinopsis
          sinopsisJp
          year
        }
      }
    }
  }
  `)

  if (!allImages.length) {
    //1 取得した画像データを配列化
    const imagesOld = data.allFile.edges.map(n => n.node);
    const images = imagesOld.slice();
    //2 ファイル名でソート
    function compare(a, b) {
      const baseA = a.base;
      const baseB = b.base;

      let comparison = 0;
      if (baseA > baseB) {
        comparison = 1;
      } else if (baseA < baseB) {
        comparison = -1;
      }
      return comparison;
    }
    images.sort(compare);
    for (let i = 0; i < images.length; i++) {
      images[i].index = i;
    }

    //3 JSONデータを配列化
    const descJsons = data.allSuperCompressedLiteraryIllustrationJson.edges.map(n => n.node);
    //4 JSONもファイル名でソート
    function compareJason(a, b) {
      const baseA = a.filename;
      const baseB = b.filename;

      let comparison = 0;
      if (baseA > baseB) {
        comparison = 1;
      } else if (baseA < baseB) {
        comparison = -1;
      }
      return comparison;
    }
    descJsons.sort(compareJason);
    //5 画像データとJSONデータを結合した配列を作成
    for (let i = 0; i < images.length; i++) {
      allImages[i] = {
        index: i,
        image: images[i],
        filename: descJsons[i].filename,
        author: descJsons[i].author,
        authorJp: descJsons[i].authorJp,
        surname: descJsons[i].surname,
        surnameJp: descJsons[i].surnameJp,
        title: descJsons[i].title,
        titleJp: descJsons[i].titleJp,
        titleJpSort: descJsons[i].titleJpSort,
        sinopsis: descJsons[i].sinopsis,
        sinopsisJp: descJsons[i].sinopsisJp,
        year: Number(descJsons[i].year),
      }
    }
    sortedbyFilename = deepCopy(allImages);
    OriginalImagesLength = allImages.length
  }


  /**
   * サムネイル用
  */
  let storedIsDownSort = "true", storedSortNum = 0;
  if (typeof window !== `undefined`) {
    storedIsDownSort = localStorage.getItem("storedIsDownSort") || "true";
    storedSortNum = localStorage.getItem("storedSortNum") || 0;
  }

  let [isDownSort, setIsDownSort] = useState(storedIsDownSort === "true");//"true"がデフォルト
  let [sortNum, setSortNum] = useState(Number(storedSortNum));//0がデフォルト
  let [searchTxt, setSearchTxt] = useState("");

  function changeSearchTxt() {
    clearTimeout(timerID);
    setTimeout(() => {
      const searchBox = document.getElementById("searchBox")
      setSearchTxt(searchBox.value)
      //console.log(searchBox.value)
    }, 1000)
  }

  function compareFilename(a, b) {
    const baseA = a.filename;
    const baseB = b.filename;
    let comparison = 0;
    if (baseA > baseB) {
      comparison = 1;
    } else if (baseA < baseB) {
      comparison = -1;
    }
    return comparison;
  }

  function compareSurname(a, b) {
    const baseA = a.surname;
    const baseB = b.surname;
    let comparison = 0;
    if (baseA > baseB) {
      comparison = 1;
    } else if (baseA < baseB) {
      comparison = -1;
    }
    return comparison;
  }
  function compareSurnameJp(a, b) {
    const baseA = a.surnameJp;
    const baseB = b.surnameJp;
    return baseA.localeCompare(baseB, 'ja');
  }
  function compareTitle(a, b) {
    const baseA = a.title;
    const baseB = b.title;
    let comparison = 0;
    if (baseA > baseB) {
      comparison = 1;
    } else if (baseA < baseB) {
      comparison = -1;
    }
    return comparison;
  }
  function compareTitleJp(a, b) {
    const baseA = a.titleJpSort;
    const baseB = b.titleJpSort;
    return baseA.localeCompare(baseB, 'ja');
  }
  function compareYear(a, b) {
    const baseA = a.year;
    const baseB = b.year;
    let comparison = 0;
    if (baseA > baseB) {
      comparison = 1;
    } else if (baseA < baseB) {
      comparison = -1;
    }
    return comparison;
  }

  function sortAllImages(isDwSort, sNum) {
    let oriImages = [];
    if (searchTxt.length) {
      if (searchTxt !== preSearchTxt) {
        if (sNum !== 99) preSearchTxt = searchTxt;
        const st = searchTxt.toLowerCase()
        searchedImages = deepCopy(sortedbyFilename).filter(value =>
          value.author.toLowerCase().indexOf(st) !== -1
          || value.authorJp.toLowerCase().indexOf(st) !== -1
          || value.title.toLowerCase().indexOf(st) !== -1
          || value.titleJp.toLowerCase().indexOf(st) !== -1
          || value.sinopsis.toLowerCase().indexOf(st) !== -1
          || value.sinopsisJp.toLowerCase().indexOf(st) !== -1
        )
      }
      oriImages = searchedImages.sort(compareFilename);
    } else oriImages = deepCopy(sortedbyFilename);
    if (sNum === 99) { //ランダムソート
      if (!isRandomSorted || searchTxt !== preSearchTxt) {
        isRandomSorted = true;
        randomSort(oriImages);
        allImages = oriImages;
        randomSortedImages = deepCopy(oriImages);
      }
      preSearchTxt = searchTxt;
      if (!isDwSort) allImages = deepCopy(randomSortedImages).reverse();
      else allImages = deepCopy(randomSortedImages);
    } else { //ランダムソート以外
      isRandomSorted = false;
      if (sNum === 0) allImages = oriImages;
      else if (sNum === 1) {
        if (lang === "ja") {
          if (sortedBySurnameJP.length && oriImages.length === OriginalImagesLength)
            allImages = deepCopy(sortedBySurnameJP);
          else {
            allImages = oriImages.sort(compareSurnameJp);
            if (oriImages.length === OriginalImagesLength) sortedBySurnameJP = deepCopy(allImages);
          }
        } else {
          if (sortedBySurname.length && oriImages.length === OriginalImagesLength)
            allImages = deepCopy(sortedBySurname);
          else {
            allImages = oriImages.sort(compareSurname);
            if (oriImages.length === OriginalImagesLength) sortedBySurname = deepCopy(allImages);
          }
        }
      }
      else if (sNum === 2) {
        if (lang === "ja") {
          if (sortedByTitleJp.length && oriImages.length === OriginalImagesLength)
            allImages = deepCopy(sortedByTitleJp)
          else {
            allImages = oriImages.sort(compareTitleJp);
            if (oriImages.length === OriginalImagesLength) sortedByTitleJp = deepCopy(allImages);
          }
        } else {
          if (sortedByTitle.length && oriImages.length === OriginalImagesLength)
            allImages = deepCopy(sortedByTitle)
          else {
            allImages = oriImages.sort(compareTitle);
            if (oriImages.length === OriginalImagesLength) sortedByTitle = deepCopy(allImages);
          }
        }
      }
      else if (sNum === 3) {
        if (sortedByYear.length && oriImages.length === OriginalImagesLength)
          allImages = deepCopy(sortedByYear);
        else {
          allImages = oriImages.sort(compareYear);
          if (oriImages.length === OriginalImagesLength) sortedByYear = deepCopy(allImages);
        }
      }
      if (!isDwSort) allImages.reverse();
    }


    //indexを付け直す
    for (let i = 0; i < allImages.length; i++) {
      allImages[i].index = i;
    }
  }

  function CreateThumbNails() {
    if (sortNum !== preSortNum || isDownSort !== preIsDownSort || preLang !== lang || searchTxt !== preSearchTxt) {
      preSortNum = sortNum;
      preIsDownSort = isDownSort;
      preLang = lang;
      //console.log("sort!", isDownSort, sortNum);
      sortAllImages(isDownSort, sortNum)
      if (typeof window !== `undefined`) {
        localStorage.setItem("storedIsDownSort", String(isDownSort));
        localStorage.setItem("storedSortNum", String(sortNum));
      }
    }
    return <div
      className={thumNails}
    >
      {allImages.map((node) => (
        <div
          className={isShow && picNum === node.index ? `${thumNail} ${curThumNail}` : thumNail}
          onClick={event => handleModal(event, node.index)}
          onKeyDown={event => handleModalKey(event, node.index)}
          role="button"
          tabIndex={0}
          key={node.filename}
          id={"pic" + String(node.index)}
          title={lang !== "ja" ? `${node.author} "${node.title}"` : `${node.authorJp}「${node.titleJp}」`}
        >
          <GatsbyImage
            image={node.image.childImageSharp.gatsbyImageData}
            alt={lang !== "ja" ? `${node.author} "${node.title} : ${node.sinopsis}"` : `${node.authorJp}「${node.titleJp}」`}
            className={thumbImg} />
        </div>
      ))}
    </div>
  }



  /**
   * モダール用
   */
  const [isShow, setIsShow] = useState(false);
  const [picNum, setPicNum] = useState(0);

  function handleModal(event, index) {
    event.stopPropagation();
    if (typeof index == "number") {
      setPicNum(index);
    }
    setIsShow(!isShow);
  }

  function handleModalKey(event, index) {
    if (event.key === "Enter") { //Enter key
      if (typeof index == "number") {
        setPicNum(index);
      }
      setIsShow(true);
    }
  }

  function clickModal(event) {
    event.stopPropagation();
    const modal = document.getElementById("modalContent");
    const clientRect = modal.getBoundingClientRect();

    if (event.clientX > clientRect.left + clientRect.width * 0.5) {
      setPicNum(picNum => (picNum + 1) % allImages.length);
    } else {
      setPicNum(picNum => picNum - 1 < 0 ? allImages.length - 1 : picNum - 1);
    }
  }

  //for touchscreen
  useEffect(() => {
    function plusPicNum() {
      setPicNum(picNum => (picNum + 1) % allImages.length);
    }

    function minusPicNum() {
      setPicNum(picNum => picNum - 1 < 0 ? allImages.length - 1 : picNum - 1);
    }

    let handleTouchStart, handleTouchMove, handleTouchEnd;
    if (getIsTouchscreen()) {
      let startX;
      let moveX;
      let dist = 150;
      let isStart = false;

      handleTouchStart = e => {
        //e.preventDefault();
        if (!isStart) {
          isStart = true;
          startX = e.touches[0].pageX;
        }
      }
      handleTouchMove = e => {
        //e.preventDefault();
        moveX = e.changedTouches[0].pageX;
      }
      handleTouchEnd = () => {
        if (isStart) {
          isStart = false;
          if (startX && moveX) {
            if (isShow && startX > moveX && startX > moveX + dist) { // 右から左にスワイプ
              //e.preventDefault()
              plusPicNum();
            } else if (isShow && startX < moveX && startX + dist < moveX) { // 左から右にスワイプ
              //e.preventDefault()
              minusPicNum();
            }
            startX = null;
            moveX = null;
          }
        }
      }

      document.addEventListener('touchstart', handleTouchStart);
      document.addEventListener('touchmove', handleTouchMove);
      document.addEventListener('touchend', handleTouchEnd);
    }
    return () => {
      if (getIsTouchscreen()) {
        document.removeEventListener('touchstart', handleTouchStart);
        document.removeEventListener('touchmove', handleTouchMove);
        document.removeEventListener('touchend', handleTouchEnd);
      }
    }
  }, [picNum, isShow])



  //キーボード操作でモダール画像チェンジ
  useEffect(() => {
    function handlePicNumKey(event) {
      if (event.key === "ArrowRight" || event.key === "Tab") {
        let result = (picNum + 1) % allImages.length;
        setPicNum(result);
      } else if (event.key === "ArrowLeft") {
        let result = picNum - 1 < 0 ? allImages.length - 1 : picNum - 1;
        setPicNum(result);
      } else if (event.key === "Escape") {
        setIsShow(false);
      }
    }

    //画像選択にfocusをシンクロさせる
    if (isShow) {
      document.getElementById(`pic${String(picNum)}`).focus();
    }

    window.addEventListener('keydown', handlePicNumKey);
    return () => {
      window.removeEventListener('keydown', handlePicNumKey);
    }
  }, [picNum, isShow])

  function CreateModal() {
    return <div
      className={
        isShow ? `${modalArea} ${isShowCSS}` : modalArea
      }
    >
      <div
        className={modalBg}
        onClick={event => handleModal(event)}
        onKeyDown={() => { }}
        role="button"
        tabIndex={0}
      >

        <div
          className={
            isShow ? `${modalWrapperWrapper} ${modalWrapperScale}` : modalWrapperWrapper
          }
          style={{
            width:
              winSize.width < winSize.height ? //縦長？
                winSize.width * 0.9 + "px"
                : winSize.width * 0.9 + "px"
          }}
        >

          <div
            className={modalWrapper}

          >
            <div
              onClick={event => clickModal(event)}
              onKeyDown={() => { }}
              role="button"
              tabIndex={0}
              title={lang !== "ja" ? allImages[picNum].sinopsis : ""}
              id="modalContent"
            >
              <GatsbyImage
                className={modalContentCSS}
                image={allImages[picNum].image.childImageSharp.gatsbyImageData}
                style={{
                  width:
                    winSize.width < winSize.height ? //縦長？
                      "100%"
                      : (winSize.height - (lang === "ja" ? 100 : 40)) * 0.925 + "px",
                  maxWidth: 640 + "px"
                }}
                alt={lang !== "ja" ? `${allImages[picNum].author} "${allImages[picNum].title} : ${allImages[picNum].sinopsis}"` : `${allImages[picNum].authorJp}「${allImages[picNum].titleJp}」`}
              />

            </div>
            <div
              className={decription}
              style={{
                marginTop: lang !== "ja" ? (winSize.width > 28 * (allImages[picNum].authorJp.length + allImages[picNum].titleJp.length)
                  ? 15 : 10) + "px" : (winSize.width > 28 * (allImages[picNum].authorJp.length + allImages[picNum].titleJp.length)
                    ? 12 : 10) + "px",
                cursor: 'auto'
              }}
              onClick={event => event.stopPropagation()}
              onKeyDown={() => { }}
            >

              <p>
                {lang !== "ja"
                  ? winSize.width > 14 * (allImages[picNum].author.length + allImages[picNum].title.length)
                    ? `${allImages[picNum].author} "${allImages[picNum].title}"`
                    : `${allImages[picNum].author} `
                  : winSize.width > 28 * (allImages[picNum].authorJp.length + allImages[picNum].titleJp.length)
                    ? `${allImages[picNum].authorJp}「${allImages[picNum].titleJp}」`
                    : `${allImages[picNum].authorJp}`
                }
              </p>
              <p>
                {lang !== "ja"
                  ? winSize.width > 14 * (allImages[picNum].author.length + allImages[picNum].title.length)
                    ? ``
                    : `"${allImages[picNum].title}"`
                  : winSize.width > 28 * (allImages[picNum].authorJp.length + allImages[picNum].titleJp.length)
                    ? ``
                    : `「${allImages[picNum].titleJp}」`
                }
              </p>
            </div>
            <div
              className={decription2}
              style={{
                cursor: 'auto'
              }}
              onClick={event => event.stopPropagation()}
              onKeyDown={() => { }}
            >
              {lang !== "ja" ? "" : allImages[picNum].sinopsisJp}
            </div>
          </div>
        </div>
      </div>
      <span
        className={close}
        onClick={event => handleModal(event)}
        onKeyDown={() => { }}
        role="button"
        tabIndex={0}
      >
        ×
      </span>
    </div>
  }





  return <>
    <Seo
      title={lang !== "ja" ? "Super-compressed Literary Illustration" : "超縮約文学イラスト"}
      description={lang !== "ja" ?
        `Draw illustrations based on synopses of famous literary works created by AI.` :
        "有名な文学作品の縮約あらすじをAIに作らせ、そのテキストを基に絵を描くイラスト（漫画）作品"
      }
      lang={lang}
      image="https://www.drawinghell.com/ogp_images/seo_superCompressedLiteraryIllustration.jpg"
      pathname="/work/superCompressedLiteraryIllustration"
    />

    {/**
       * ページ＆サムネイル
       */}
    <Layout
      checked="worksParts"
      winSize={winSize}
    >
      <Article
        isLangBar="true"
        winSize={winSize}
        setLang={setLanguage}
        lang={props.lang}
      >
        <h1
          className={eachTitle}
          style={{
            letterSpacing: `${lang !== "ja" ? "0" : "0.1rem"}`
          }}
        >
          {lang !== "ja" ? "Super-compressed Literary Illustration" : "超縮約文学イラスト"}
        </h1>
        <diV className={desc2}>
        <p >
          {lang !== "ja" ?
            `Draw illustrations based on AI-generated super-compressed synopses of famous literary works` :
            `AIが作った有名文学作品の超短縮あらすじをもとに絵を描く`}
        </p>

        </diV>
        <p className={desc}>
          {lang !== "ja" ?
            `Illustration, 2023-` :
            `イラスト、2023年～`
          }
        </p>
        <a href="https://docs.google.com/spreadsheets/d/1DaeH2Rkj8gFvg1yB78ZEoDF5S4KWaprqxLOsGjGtPCc"
            target="_blank"
            rel="noopener noreferrer"
            className={desc3}
          >
            {lang !== "ja" ? "Spreadsheet of literary works used" : "使用文学作品一覧"}
          </a>
        {/**
         * セレクト
        */}
        <div
          className={buttons}
        >
          <search>
            <input
              className={search}
              id="searchBox"
              placeholder={lang !== "ja" ? "Filter by keyword" : "キーワードで絞り込み"}
              onChange={e => changeSearchTxt()}
              title={lang !== "ja" ? "filter" : "絞り込み"}
              autocomplete="off"
            />
          </search>
          <select
            className={select}
            value={String(sortNum)}
            onChange={e => setSortNum(Number(e.target.value))}
            title={lang !== "ja" ? "sort" : "並べ替え"}
          >
            <p>{lang !== "ja" ? "Sort by " : ""}</p>

            <option value="0">
              {lang !== "ja" ? "Sort by Creation day" : "作成日を基準に並べ替え"}
            </option>
            <option value="1">
              {lang !== "ja" ? "Sort by Author's surname" : "作者名を基準に並べ替え"}
            </option>
            <option value="2">
              {lang !== "ja" ? "Sort by Title" : "作品名を基準に並べ替え"}
            </option>
            <option value="3">
              {lang !== "ja" ? "Sort by Publication year" : "出版年を基準に並べ替え"}
            </option>
            <option value="99">
              {lang !== "ja" ? "Sort Randomly" : "ランダムに並べ替え"}
            </option>
          </select>
          <button
            type="button"
            className={button}
            onClick={e => setIsDownSort(!isDownSort)}
            title={lang !== "ja" ?
              isDownSort ? "Ascending Order" : "Descending Order"
              : isDownSort ? "昇順" : "降順"}
          >
            {isDownSort ? "⇧" : "⇩"}
          </button>
        </div>
        {/**
         * サムネイル
        */}
        <div
          id="thumbnailParent"
        >
          {CreateThumbNails()}
        </div>
      </Article>
    </Layout>


    {/**
      * モーダル      
    */}
    {allImages.length && allImages.length > picNum ? CreateModal() : ""}

  </>;
}

export default SuperCompressedLiteraryIllustration

/*
        <p >
          {lang !== "ja" ?
            `An illustration work on the theme of "Illustration."` :
            `「イラスト」をテーマにしたイラスト作品`}
        </p>
*/